import React from 'react'
import items from "../../../../context/data/zertifikate.json"
import PDFTemplate from '../../templates/pdf-template'
import { Helmet } from "react-helmet"
import { genTitle } from '../../../../helper/util'

const Zertifikate: React.FC = () => {
    return (
        <>
        <Helmet>
            <title> {genTitle("Zertifizierungen")} </title>
            <meta name="description" content="Übersicht unserer Zertifikate, Oberflächentechnik, Schweißtechnik" />
        </Helmet>

        <PDFTemplate items={items} />
        </>
    )
}

export default Zertifikate
