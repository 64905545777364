import React from "react"
import kwl_meta from "../../context/data/general.json"
import { Desktop, Mobile, DesktopWide } from "../../modules/responsive"

const { title } = kwl_meta
const Title = () => {
  return (
    <h1 className="pad-px-10">
      <DesktopWide>{title.full}</DesktopWide>
      <Desktop>{title.full}</Desktop>
      <Mobile>{title.short}</Mobile>
    </h1>
  )
}

export default Title
