import React from "react"
import "./style.scss"
import Address from "./Address"
import kwl_meta from "../../context/data/general.json"
import socials from "../../context/data/socials.json"
//@ts-ignore
import { SocialIcon } from "react-social-icons"
import menu_conf from "../../context/data/menu.json"
import { NavLink } from "react-router-dom"
import Separator from "../../modules/separator"
import { Mobile } from "../../modules/responsive"

const { bottom: nav_items } = menu_conf
const { contact } = kwl_meta
const Footer = () => {
  return (
    <footer className="color-1 align-center space-evenly flex wrap-reverse z2">
      <div className="flex center">
        <Address className="flex column ws-no-break" {...contact} />
      </div>
      {nav_items && (
        <>
          <Mobile>
            <Separator mode="horizontal" />
          </Mobile>
          <div>
            <nav className="flex column center">
              {nav_items.map(({ title, to }) => (
                <NavLink className="undecorated" key={to} to={to}>
                  {" "}
                  {title}{" "}
                </NavLink>
              ))}
            </nav>
          </div>
        </>
      )}
      <Mobile>
        <Separator mode="horizontal" />
      </Mobile>
      <div>
        <section id="socials">
          {Object.entries(socials).map(([key, value]) => (
            <SocialIcon bgColor="white" key={key} url={value} />
          ))}
        </section>
      </div>
    </footer>
  )
}

export default Footer
