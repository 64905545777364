import React, {
  ReactElement,
  useState,
  useCallback,
  cloneElement,
  useMemo,
} from "react"
import Collapse from "@mui/material/Collapse"

type AnyProps = {
  [key: string]: any
  className?: string
}

type DropdownProps = {
  defaultOpen?: boolean
  children: {
    Head: ReactElement
    Tail: ReactElement[]
  }
  As?: (props: any) => ReactElement
  headProps?: AnyProps
  tailProps?: AnyProps
  tailItemProps?: AnyProps
  hover?: boolean
}

type DropdownTailProps = {
  Tail: ReactElement[]
  tailItemProps?: AnyProps
}
const DropdownTail: React.FC<DropdownTailProps> = React.memo(
  ({ Tail, tailItemProps = {} }) => {
    return (
      <>
        {Tail.map((item, i) => (
          <li key={i}>
            {cloneElement(item, {
              ...tailItemProps,
              className: `${item.props.className || ""} ${
                tailItemProps.className || ""
              } dropdown-item`,
            })}
          </li>
        ))}
      </>
    )
  }
)

const Dropdown: React.FC<DropdownProps> = ({
  children: { Head, Tail },
  defaultOpen = false,
  As = null,
  headProps = {},
  tailItemProps = {},
  tailProps = {},
  hover = false,
}) => {
  if (hover && As === null)
    throw new Error("In hover mode As prop is required!")
  const [isOpen, setOpen] = useState(defaultOpen)
  const toggle = useCallback(() => {
    setOpen(state => !state)
  }, [setOpen])

  const head = useMemo(() => {
    return cloneElement(Head, {
      ...headProps,
      onClick: !hover
        ? () => {
            if (typeof Head.props.onClick === "function") Head.props.onClick()
            toggle()
          }
        : Head.props.onClick,
      className: `${Head.props.className || ""} ${
        headProps.className || ""
      } dropdown-head`,
    })
  }, [Head, toggle, headProps, hover])

  const content = (
    <>
      {head}
      <Collapse in={isOpen} component="ul">
        <DropdownTail Tail={Tail} tailItemProps={tailItemProps}></DropdownTail>
      </Collapse>
    </>
  )

  if (Tail.length === 0) return Head
  return As === null ? (
    <>{content}</>
  ) : (
    <As onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      {content}
    </As>
  )
}

export default React.memo(Dropdown)
