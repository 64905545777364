import React from "react"
import "./style.scss"
import people from "../../../../context/data/people.json"
import Person from "./Person"
import { Helmet } from "react-helmet"
import { genTitle } from "../../../../helper/util"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"

const Ansprechpartner = () => {
  return (
    <Container>
      <Helmet>
        <title> {genTitle("Ansprechpartner")} </title>
        <meta
          name="description"
          content="Liste unserer Ansprechpartner mit Name, Bild, Telefon, Email, Rolle"
        />
      </Helmet>

      <Grid container spacing={5} justifyContent="center">
        {people.map(p => (
          <Grid
            item
            key={p.name}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            style={{ maxWidth: "500px" }}
          >
            <Person
              name={p.name}
              tel={p.tel}
              mail={p.email}
              img={p.image}
              role={p.rolle}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default Ansprechpartner
