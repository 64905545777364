import MBGBP from "./Maschinenbediener_Gesenkbiegepressen.json"
// import MBGL from "./Maschinenbediener_Laser.json"
// import SCHWEISSER from "./Schweisser.json"
// import ZERSPANER from "./Zerspanungsmechaniker.json"
import ZERSPANER_AZ from "./Zerspanungsmechaniker_az.json"
// import BSG from "./BSG.json"
import PULVER from "./Pulver.json"
import INDUSTRIE_MEISTER from "./Industriemeister_Metall.json"
import LAGERFACHKRAFT from "./Lagerfachkraft.json"
import AZUBI_KONSTRUKTIONSMECHANIKER from "./Azubi_Konstruktion.json"
import Shared from "./shared.json"
import cloneDeep from "lodash/cloneDeep"

type ExtraJobFields = { image?: string }
export type JobPosting = typeof Shared & typeof MBGBP & ExtraJobFields
const Jobs = {
  // BSG,
  // MaschienenbedienerGesenkbiegepressen: MBGBP,
  // Schweisser: SCHWEISSER,
  // MaschienenbedienerLaser: MBGL,
  Pulver: PULVER,
  // Zerspaner: ZERSPANER,
  ZerspanerAusbildung: ZERSPANER_AZ,
  IndustrieMeister: INDUSTRIE_MEISTER,
  Lagerfachkraft: LAGERFACHKRAFT,
  KonstruktionsmechanikerAusbildung: AZUBI_KONSTRUKTIONSMECHANIKER,
} as const
const Default: Record<string, JobPosting> = Object.entries(Jobs).reduce(
  (acc, [key, value]) =>
    Object.assign(acc, { [key]: Object.assign(cloneDeep(Shared), value) }),
  {} as Record<keyof typeof Jobs, JobPosting>
)
export default Default
