import React from "react"
import Header from "./layout/header"
import Footer from "./layout/footer"
import Main from "./layout/main"
import { BrowserRouter } from "react-router-dom"
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material"

import "./App.scss"
import "./context/theme/general.scss"
import "./context/theme/constants.scss"

let theme = createTheme()
theme = responsiveFontSizes(theme)

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <Header />
          <Main />
          <Footer />
        </BrowserRouter>
        <div className="Background" />
      </div>
    </ThemeProvider>
  )
}

export default App
