import React from "react"
import Title from "./Title"
import Navigation from "./Navigation"
import "./style.scss"
import menu_conf from "../../context/data/menu.json"
import Logo from "./Logo"
import { NavLink } from "react-router-dom"
import unionBy from "lodash/unionBy"
import { MENU_RESPONSIVE_QUERY } from "../../context/theme/constants"
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery"

const { top: MenuItems, bottom: MobileAdditionalItems } = menu_conf
export const ENABLE_STELLENANGEBOTE = false

let menuItems = MenuItems

if (!ENABLE_STELLENANGEBOTE) {
  const index = menuItems.findIndex(item => item.title === "Stellenangebote")
  menuItems.splice(index, 1)
}

const MobileItems = unionBy(
  menuItems,
  MobileAdditionalItems.map(e => {
    return { ...e, children: [] }
  }),
  "to"
)

const Header: React.FC = () => {
  const showFull = useMediaQuery(MENU_RESPONSIVE_QUERY)

  return (
    <>
      {showFull ? (
        <header className="flex align-center space-between pad-px-5 color-1 z5">
          <NavLink className="undecorated flex align-center" to="/">
            <Logo />
            <Title />
          </NavLink>
          <Navigation items={MenuItems} />
        </header>
      ) : (
        <header className="flex align-center space-between pad-px-5 color-1 z5">
          <NavLink to="/">
            <Logo />
          </NavLink>
          <Title />
          <Navigation items={MobileItems} />
        </header>
      )}
      <div className="header-fix"></div>
    </>
  )
}

export default Header
