import React, { useState, useCallback, useEffect } from "react"
import { NavigationProps, MenuItemType } from "./Navigation"
import Burger from "../../modules/burger"
import { NavLink, useLocation } from "react-router-dom"
import Dropdown from "../../modules/dropdown"
import Slide from "@mui/material/Slide"

const MobileNavitationMenuItem: React.FC<MenuItemType> = React.memo(
  ({ title, to, children }) => {
    return children.length === 0 ? (
      <NavLink className="flex center undecorated" to={to}>
        {title}
      </NavLink>
    ) : (
      <Dropdown>
        {{
          Head: <span className="flex center color-1">{title}</span>,
          Tail: children.map((item, i) => (
            <MobileNavitationMenuItem key={i} {...item} />
          )),
        }}
      </Dropdown>
    )
  }
)

const MobileNavigationMenu: React.FC<NavigationProps> = React.memo(
  ({ items }) => {
    return (
      <ul className="flex column undecorated color-1">
        {/* <MobileNavitationMenuItem to="/" title="Home" children={[]} /> */}
        {items.map((item, i) => (
          <li key={i}>
            <MobileNavitationMenuItem {...item} />
          </li>
        ))}
      </ul>
    )
  }
)

const MobileNavigation: React.FC<NavigationProps> = React.memo(({ items }) => {
  const [isOpen, setOpen] = useState(false)
  const toggle = useCallback(() => {
    setOpen(state => !state)
  }, [setOpen])

  const location = useLocation()
  useEffect(() => setOpen(false), [location, setOpen])

  return (
    <nav className="thin">
      <Burger isOpen={isOpen} onClick={toggle} />
      <Slide direction="left" in={isOpen}>
        <div className="fix-container color-1">
          <MobileNavigationMenu items={items} />
        </div>
      </Slide>
    </nav>
  )
})

export default MobileNavigation
