import React, { CSSProperties, useCallback, useState } from "react"
import LightBox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useToggle, useWebP } from "../../helper/hooks"
import "./style.scss"

interface LightBoxContainerProps {
    images: {
        src: string
        alt: string
    }[]
    style?: CSSProperties
    imgStyle?: CSSProperties
    className?: string
    initialIndex?: number
}

const LightBoxContainer: React.FC<LightBoxContainerProps> = ({
    images,
    style = {},
    imgStyle = {},
    className,
    initialIndex = 0,
}) => {
    const [index, setIndex] = useState<number>(initialIndex)
    const [open, , setOpen] = useToggle(false)

    const close = useCallback(() => {
        setOpen(false)
    }, [setOpen])
    const next = useCallback(() => {
        setIndex(index => (index + 1) % images.length)
    }, [setIndex, images.length])
    const prev = useCallback(() => {
        setIndex(index => (index + images.length - 1) % images.length)
    }, [setIndex, images.length])

    const openAt = useCallback(
        (to: number) => {
            setIndex(to)
            setOpen(true)
        },
        [setIndex, setOpen]
    )

    const imagesSrcs = useWebP(images.map(img => img.src)) as string[]

    if (images.length === 0) return null
    return (
        <div
            className={`LightBoxContainer${className ? ` ${className}` : ""}`}
            style={style}
        >
            {images.map(({ src, alt }, i) => (
                <img
                    src={src}
                    alt={alt}
                    key={src}
                    style={imgStyle}
                    onClick={() => openAt(i)}
                />
            ))}

            {open && (
                <LightBox
                    mainSrc={imagesSrcs[index]}
                    nextSrc={imagesSrcs[(index + 1) % images.length]}
                    prevSrc={
                        imagesSrcs[(index + images.length - 1) % images.length]
                    }
                    onCloseRequest={close}
                    onMoveNextRequest={next}
                    onMovePrevRequest={prev}
                />
            )}
        </div>
    )
}

export default React.memo(LightBoxContainer)
