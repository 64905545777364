import React from "react"
import Slider from "../../../../modules/slider"
import { CALC_SLIDER_TOP_MARGIN } from "../../../../context/theme/constants"
import Separator from "../../../../modules/separator"
import "./style.scss"
import LinkCard from "../../../../modules/LinkCard/LinkCard"
import { Helmet } from "react-helmet"
import { genTitle } from "../../../../helper/util"
import { Typography } from "@mui/material"

const videos = ["/video/Cut/Werbevideo-Cut-compressed28.m4v"]

const FrontPage = () => {
  return (
    <>
      <Helmet>
        <title> {genTitle("Home")} </title>
        <meta
          name="description"
          content="Homepage der KWL Blechverarbeitung GmbH - Willkommen!"
        />
      </Helmet>

      <Slider
        className="flex fullscreenwidth"
        style={{ marginTop: CALC_SLIDER_TOP_MARGIN }}
        hm={0.7}
        auto={5000}
      >
        {videos.map(v => (
          <video key={v} loop autoPlay muted src={v}></video>
        ))}
        {}
      </Slider>
      <div className="FrontPage-Nav flex row wrap-reverse space-around fullscreenwidth">
        <LinkCard
          title="Produktionsverfahren"
          img="/image/schweissen_nav.jpg"
          to="/Produktionsverfahren"
        />
        <LinkCard
          title="Dienstleistungen"
          img="/image/Büro%20-%20Konstruktionsbüro.jpg"
          to="/Dienstleistungen"
        />
        {/* <LinkCard 
        title="Unternehmen" 
        img="/image/B%C3%BCro%20-%20Gesch%C3%A4ftsf%C3%BChrung.JPG" 
        to="/Unternehmen"
      /> */}
        <LinkCard
          title="Unternehmen"
          img="/image/UU/uufsc.jpg"
          to="/Unternehmen"
        />
        <LinkCard
          title="Ansprechpartner"
          img="/image/Kontakt.jpg"
          to="/Unternehmen/Ansprechpartner"
        />
        {/* <LinkCard 
        title="Anfragen" 
        img="/image/Kontakt.jpg" 
        to="/Anfragen"
      /> */}
      </div>
      <div
        style={{
          fontSize: "1.5em",
        }}
      >
        <Separator mode="horizontal"></Separator>

        <Typography
          className="slogan kwl-colors"
          variant="h2"
          style={{ fontWeight: "bold" }}
        >
          {" "}
          <strong className="fontColor-3" style={{ fontWeight: "bolder" }}>
            Blech
          </strong>{" "}
          - und alles, was wir daraus machen!{" "}
        </Typography>

        <article>
          <p>
            Die KWL Blechverarbeitung GmbH ist ein inhabergeführtes Unternehmen,
            das seit 1995 erfolgreich am Markt agiert. Über die letzten Jahre
            haben wir uns, durch die ständige Erschließung neuer
            Produktionsverfahren, zu einem der führenden
            Full-Service-Dienstleister in der Region entwickelt.
          </p>
          <p>
            Blechverarbeitung von der Platine bis zur einbaufähigen Baugruppe,
            vom Prototyp bis zur Serie, in den unterschiedlichsten Materialien
            und Legierungen. Das ist der Service, den wir unseren Kunden bieten.
          </p>
          <p>
            Exakte Planung, effizientes Handeln, sowie gut ausgebildete und
            motivierte Mitarbeiter sind das Erfolgskonzept, durch das wir uns
            auf einem herausforderungsreichen Markt behaupten können.{" "}
          </p>
          <p>
            An unserem Standort in Neuwied produzieren wir auf ca. 8.500 m²
            Produktionsfläche und beschäftigen mehr als 100 Mitarbeiter.{" "}
          </p>
          <p>
            Wenn Sie auf der Suche nach einem kompetenten Partner im Bereich der
            Blechverarbeitung sind, kontaktieren Sie bitte unser qualifiziertes
            Beratungsteam.
          </p>
          <p>Wir freuen uns darauf, Sie kennenzulernen.</p>
        </article>
      </div>
    </>
  )
}

export default FrontPage
