import React from 'react'
import WithTopSlider from '../../templates/with-top-slider'
import { Helmet } from "react-helmet"
import { genTitle } from '../../../../helper/util'

const videos = [
  "/video/Cut/Innerelogistik-Cut-compressed26.m4v"
]

const Logistik = () => {
  return (
    <>
    <Helmet>
      <title> {genTitle("Logistik")} </title>
    </Helmet>

    <WithTopSlider items={videos} />
    <div id="Logistik">
      <p>Unser kompetentes Logistik Team kümmert sich um zügige Einlagerung des Rohmaterials, eine effiziente und sichere Kommissionierung und die termingerechte Auslieferung.</p>
      <p>Neben einer engen Zusammenarbeit mit Speditionen und Paketdiensten betreiben wir auch einen eigenen Fuhrpark, um schnell und flexibel auf die Wünsche und Bedürfnisse unserer Kunden reagieren zu können.</p>
      <p>Unser Fuhrpark deckt vom Kleintransporter bis zum Sattelzug alle Bedürfnisse ab.</p>
    </div>
    </>
  )
}

export default Logistik
