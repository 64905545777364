import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  CSSProperties,
  ReactElement,
  cloneElement,
  forwardRef,
  useRef,
} from "react"
import { modulo, orZero } from "../../helper/math"
import { Transition, animated } from "@react-spring/web"
import "./style.scss"
import { SizeMe } from "react-sizeme"
import { useDrag } from "react-use-gesture"
import { useToggle } from "../../helper/hooks"
import uniqueId from "lodash/uniqueId"
import { VIDEO_HEIGHT_TO_WIDTH } from "../../context/theme/constants"

type SliderProps = {
  style?: CSSProperties
  className?: string
  auto?: number
  noAuto?: boolean
  children: ReactElement[]
  videoWait?: boolean
  ratio?: number
  hm?: number /* height modifier */
}

enum VideoStatus {
  Loading,
  Failed,
  Ready,
}

const animationDuration = 500
export type Ref = HTMLDivElement

const Slider = forwardRef<Ref, SliderProps>(
  (
    {
      children: _children,
      style,
      className,
      auto = 5000,
      noAuto = false,
      videoWait = true,
      ratio = 9 / 16,
      hm = 1,
    },
    ref
  ) => {
    const [index, _setIndex] = useState(0)
    const [blocked, block] = useState(false)
    const [isPlaying, , setPlayingStatus] = useToggle(false)
    const [videoStatus, setVideoStatus] = useState<VideoStatus>(
      VideoStatus.Loading
    )
    const videoRef = useRef<HTMLVideoElement>()

    const length = useMemo(() => React.Children.count(_children), [_children])

    const setIndex = useCallback(
      (value: number) => {
        if (value === index || blocked || length < 2) return
        block(true)
        const _value = modulo(value, length)
        _setIndex(_value)
      },
      [index, length, _setIndex, blocked, block]
    )

    const next = useCallback(() => {
      setIndex(index + 1)
    }, [setIndex, index])

    const back = useCallback(() => {
      setIndex(index - 1)
    }, [setIndex, index])

    // transition videos before they stop playing
    const handleNearFinish = useCallback(
      (event: Event) => {
        const t = event.currentTarget
        if (t === null) throw new Error("event target null")
        if (!(t instanceof HTMLVideoElement))
          throw new Error("target is not a HTMLVideoElement")
        if (t.currentTime > t.duration - animationDuration / 1000) {
          next()
        }
      },
      [next]
    )

    const togglePlay = useCallback(() => {
      const { current } = videoRef
      if (!current) return
      if (current.paused) {
        current.play()
      } else {
        current.pause()
      }
    }, [videoRef])

    const setVideoRef = useCallback(
      (ref: HTMLVideoElement) => {
        if (ref === null) return
        videoRef.current = ref
      },
      [videoRef]
    )

    // apply props to videos
    const children = useMemo(() => {
      return React.Children.map(_children, (child, i) => {
        if (
          typeof child === "object" &&
          child !== null &&
          "type" in child &&
          child.type === "video"
        ) {
          return cloneElement(child, {
            loop: videoWait && _children.length > 1 ? false : child.props.loop,
            onTimeUpdate: _children.length > 1 ? handleNearFinish : null,
            preload: i === 0 ? "auto" : "metadata",
            onPlay: () => setPlayingStatus(true),
            onPause: (event: any) => {
              event.persist()
              if (event.target.id === videoRef.current?.id)
                setPlayingStatus(false)
            },
            onEnded: (event: any) => {
              event.persist()
              if (event.target.id === videoRef.current?.id)
                setPlayingStatus(false)
            },
            onError: () => {
              setVideoStatus(VideoStatus.Failed)
            },
            onLoadStart: () => {
              setVideoStatus(VideoStatus.Loading)
            },
            onCanPlayThrough: () => {
              setVideoStatus(VideoStatus.Ready)
            },
            ref: setVideoRef,
            id: uniqueId("Slider-Video"),
          })
        }
        return child
      })
    }, [_children, videoWait, handleNearFinish, setPlayingStatus, setVideoRef])

    // transition of non video elements
    useEffect(() => {
      if (noAuto) return
      const c = React.Children.toArray(children)[index]
      if (!c || (typeof c === "object" && (c as any).type === "video")) return
      const timeout = setTimeout(next, auto)
      return () => clearTimeout(timeout)
    }, [children, next, index, noAuto, auto])

    // autoplay fix
    useEffect(() => {
      const ti = setTimeout(() => {
        if (!videoRef.current) return
        if (videoRef.current.paused) setPlayingStatus(false)
      }, animationDuration)
      return () => clearTimeout(ti)
    }, [index, setPlayingStatus])

    // bind arrow keys
    // useEffect(() => {
    //   function handleKeyPress(event: KeyboardEvent) {
    //     switch ( event.key ) {
    //       case "ArrowLeft":
    //         back()
    //         break
    //       case "ArrowRight":
    //         next()
    //         break
    //     }
    //   }
    //   document.addEventListener("keyup", handleKeyPress)
    //   return () => document.removeEventListener("keyup", handleKeyPress)
    // },[next, back])

    // unblock after Animation
    useEffect(() => {
      if (!children || children.length < 2) return
      const timeout = setTimeout(() => block(false), animationDuration)
      return () => clearTimeout(timeout)
    }, [block, blocked, children])

    // Gestures to navigate slider items
    const bind = useDrag(({ last, direction }) => {
      const [dx] = direction
      const [adx, ady] = direction.map(c => Math.abs(c))
      if (!last) return
      if (ady > adx) return
      if (Math.abs(dx) < 0.3) return
      if (dx > 0) {
        back()
      } else {
        next()
      }
    })

    const buttonClass = useMemo(() => {
      const { Failed, Loading, Ready } = VideoStatus
      return videoStatus === Failed
        ? "error"
        : videoStatus === Loading
        ? "loading"
        : videoStatus === Ready
        ? isPlaying
          ? "playing"
          : "paused"
        : ""
    }, [videoStatus, isPlaying])

    if (length === 0 || !children) return null
    return (
      <SizeMe refreshRate={100} monitorHeight>
        {({ size }) => (
          <div
            className={`Slider ${children.length > 1 ? "with-controls" : ""} ${
              className ?? ""
            }`}
            style={{
              ...style,
              height: `${orZero(size.width) * ratio * hm}px`,
            }}
            ref={ref}
          >
            <div className="fullwidth" {...bind()} onClick={togglePlay}>
              {(children[index] as Record<string, any>).type === "video" && (
                <button
                  type="button"
                  className={`Slider-PlayButton ${buttonClass}`}
                  style={{
                    top: `${
                      orZero(size.width) * VIDEO_HEIGHT_TO_WIDTH * 0.5
                    }px`,
                    display: size.width ? "unset" : "none",
                  }}
                  aria-label="video play or pause"
                />
              )}
              <Transition<number, any>
                items={index}
                from={
                  { position: "absolute", opacity: 0 } as {
                    position: "absolute"
                  }
                }
                enter={{ position: "absolute", opacity: 1 }}
                leave={{ position: "absolute", opacity: 0 }}
                config={{
                  duration: animationDuration,
                }}
              >
                {(props: any, item: number) => (
                  <animated.div style={props} className="fullsize">
                    {children[item]}
                  </animated.div>
                )}
              </Transition>
            </div>
            {children.length > 1 && (
              <div className="controls flex center wrap-reverse">
                {React.Children.map(children, (c, i) => (
                  <input
                    type="radio"
                    name="control"
                    value={i}
                    checked={index === i}
                    className={i === index ? "active" : ""}
                    onChange={() => setIndex(i)}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </SizeMe>
    )
  }
)
export default React.memo(Slider)
