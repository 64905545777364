import React from 'react'
import PDFTemplate from '../../templates/pdf-template'
import items from "../../../../context/data/datenschutz.json"
import { Helmet } from "react-helmet"
import { genTitle } from '../../../../helper/util'

const Datenschutz = () => {
    return (
        <>
        <Helmet>
            <title> {genTitle("Datenschutz")} </title>
        </Helmet>

        <PDFTemplate  items={items} />
        </>
    )
}

export default Datenschutz
