import React, { CSSProperties } from "react"
import Slider from "../../../../modules/slider"
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery"
import {
  breakpoints,
  CALC_SLIDER_TOP_MARGIN_WITH_BREADCRUMBS,
  CALC_SLIDER_TOP_MARGIN,
} from "../../../../context/theme/constants"

type WithTopSliderProps = {
  items: (
    | string
    | {
        src: string
        style?: CSSProperties
        className?: string
      }
  )[]
}

const TopSlider: React.FC<WithTopSliderProps> = ({ items }) => {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.m - 1}px)`)

  return (
    <Slider
      className="flex fullscreenwidth"
      style={{
        marginTop: !isMobile
          ? CALC_SLIDER_TOP_MARGIN_WITH_BREADCRUMBS
          : CALC_SLIDER_TOP_MARGIN,
        zIndex: 0,
      }}
      hm={0.7}
      auto={5000}
    >
      {items.map(v => {
        // only videos for now
        return typeof v === "string" ? (
          <video key={v} loop autoPlay muted src={v}></video>
        ) : (
          <video
            key={v.src}
            loop
            autoPlay
            muted
            src={v.src}
            style={v.style}
            className={v.className}
          ></video>
        )
      })}
    </Slider>
  )
}

export default React.memo(TopSlider)
