import React, { CSSProperties } from "react"
import { NavLink } from "react-router-dom"
import { useWebP } from "../../helper/hooks"
import "./LinkCard.scss"

export interface LinkCardProps {
    img?: string
    to: string
    title: string
    imgPosition?: string
    style?: CSSProperties
}

const LinkCard: React.FC<LinkCardProps> = ({
    title,
    img,
    to,
    imgPosition = "center",
    style,
}) => {
    const img_src = useWebP(img) as string | undefined

    return (
        <NavLink
            key={title}
            style={style}
            className="LinkCard flex color-1 buttonlike shadowed undecorated"
            to={to}
        >
            <div
                style={{
                    backgroundImage: img ? `url(${img_src})` : "none",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: imgPosition,
                }}
                className="LinkCard-Container flex center fullsize"
            >
                {img ? <span> {title} </span> : <h1> {title} </h1>}
            </div>
        </NavLink>
    )
}

export default LinkCard
