import "./style.scss"
import React, { useMemo } from "react"
import { Helmet } from "react-helmet"
import { genTitle } from "../../../../helper/util"
import Typography from "@mui/material/Typography"
import LightBox from "../../../../modules/LightBoxContainer"
import { breakpoints } from "../../../../context/theme/constants"
import ImageText from "../../../../modules/ImageText"
import Divider from "../../../../modules/separator"
import { useISIE } from "../../../../helper/hooks"
import mapValues from "lodash/mapValues"

const prefix = "/image/Oberflaechenvorbehandlung/"
const links = {
  lissmac: prefix + "lissmac.jpg",
  edgeracer: prefix + "edgeracer.jpg",
  sandstrahle_1: prefix + "thumbnail.jpg",
  sandstrahle_2: prefix + "Sandstrahle_3.jpg",
  sandstrahle_3: prefix + "OFT_-_Sandstrahlen_3.jpg",
  sandstrahle_4: prefix + "OFT_-_Sandstrahle_Werkstuecke_4.jpg",
  glasperlen: prefix + "Glasperlenstrahle_(6).jpg",
  gleitschleifen: prefix + "gleitschleifen.jpg",
  vorher_nachher: prefix + "vorher_nachher.jpg",
}

const Oberflaechenvorbehandlung: React.FC = () => {
  const ie = useISIE()
  const _links = useMemo(
    () =>
      ie ? links : mapValues(links, link => link.replace(/\.\w+$/, ".webp")),
    [ie]
  )
  return (
    <>
      <Helmet>
        <title> {genTitle("Oberflächenvorbehandlung")} </title>
        {/* Description */}
      </Helmet>

      <div id="Oberflaechenvorbehandlung">
        <Typography paragraph variant="inherit">
          Bei nahezu allen Beschichtungsverfahren ist die Vorbehandlung eines
          der Schlüsselelemente. Vor allem die stark gestiegenen und weiter
          steigenden Anforderungen an die Oberflächenqualität und
          Oberflächenfunktionalität erfordern immer mehr Detailwissen im Bereich
          der Oberflächenvorbehandlung, um optimale Ergebnisse zu erzielen.
        </Typography>
        <Typography paragraph variant="inherit">
          Zum Verhindern von Haftungsproblemen und Oberflächendefekten, sowie
          der Erfüllung von Korrosionsschutzanforderungen ist eine geeignete und
          gut funktionierende Vorbehandlung unerlässlich.
        </Typography>

        <Divider mode="horizontal" />

        <section id="kantenverrundung">
          <Typography variant="h2"> Kantenverrundung </Typography>
          <section id="lissmac">
            <ImageText
              text="Innovative Blechbearbeitung durch hocheffektive, beidseitige Entgratung von lasergeschnittenen Werkstücken in nur einem Arbeitsschritt. Bei uns findet eine sortenreine Bearbeitung der Werkstücke mit einer Maschine für Edelstahlwerkstücke und einer Maschine für Aluminiumwerkstücke statt."
              img={_links.lissmac}
              imgAlt="lissmac"
              textTitle="Lissmac"
            />
          </section>
          <section id="edgeracer">
            <ImageText
              text="Der EdgeRacer wurde speziell für das gleichmäßige Kantenverrunden und Entgraten der Innen- und Außenkonturen von Blechen und ebenen Teilen entwickelt. In einem Arbeitsgang werden Ober- und Unterseite gleichzeitig bearbeitet, sodass die Teile anschließend sofort dem nächsten Prozessschritt übergeben werden können. Damit sparen wir uns Bearbeitungszeit und unseren Kunden die Kosten der manuellen Bearbeitung."
              img={_links.edgeracer}
              imgAlt="edgeracer"
              textTitle="EdgeRacer"
              ipos="left"
            />
          </section>
        </section>

        <Divider mode="horizontal" />

        <section id="Oberflaechenbehandlung">
          <Typography variant="h2"> Oberflächenbehandlung </Typography>
          <section id="Sandstrahle">
            <ImageText
              text="Wir verwenden das Sandstrahlen, um Oberflächen von arteigener oder artfremder Verunreinigung zu befreien, die Oberfläche anzurauen oder zu mattieren. Üblicherweise dient das Sandstrahlen der Oberflächenvorbereitung vor dem Beschichtungsprozess, aber auch bei der Schweißnahtvorbereitung setzen wir Sandstrahlen ein, um eine bestmögliche Verbindung zu garantieren. Für große Teile benutzen wir unsere Durchlaufanlage, während kleinere Teile in einer Handkabine gestrahlt werden können."
              textTitle="Sandstrahlen"
              img={_links.sandstrahle_1}
              imgAlt="Durchlaufanlage von rechts"
            />
            <LightBox
              className="flex wrap space-around"
              style={{ margin: "2rem 0 6rem 0" }}
              imgStyle={{
                maxHeight: breakpoints.xs * 0.6,
                margin: "5px",
              }}
              images={[
                {
                  src: _links.sandstrahle_2,
                  alt: "Durchlaufanlage von links",
                },
                {
                  src: _links.sandstrahle_3,
                  alt: "Sandstrahle",
                },
                {
                  src: _links.sandstrahle_4,
                  alt: "Werkstück Sandstrahle",
                },
              ]}
            />
          </section>

          <section id="Glasperlenstrahle">
            <ImageText
              text={[
                "Wir verwenden Glasperlenstrahlen zur eisenfreien Reinigung und Erzeugung einer dekorativen Oberfläche. Das Betriebsgemisch besteht aus einem Bruchanteil und einem kugeligen Anteil. Der kantige Anteil wirkt abrasiv, er unterstützt die Reinigungswirkung, während die runden Glasperlen den dekorativen Teil übernehmen.",
              ]}
              textTitle="Glasperlenstrahlen"
              img={_links.glasperlen}
              imgAlt="Glasperlenstrahle"
              ipos="left"
            />
            <Typography paragraph variant="inherit">
              Glasperlen sind eisenfrei und chemisch neutral und damit für die
              Bearbeitung fast aller Stoffe geeignet. Edelstahlteile bekommen
              durch das Glasperlenstrahlen eine gleichmäßige, seidenglänzende
              Oberfläche. Werkstücke aus Aluminium bekommen einen
              silbrig-metallischen Glanz.
            </Typography>
          </section>

          <section id="Gleitschleifen">
            <ImageText
              text={[
                "Bei uns kommt das Vibrationsgleitschleifen zum Einsatz. Hierbei versetzt ein großer Vibrator den gesamten Inhalt der Trommel in Schwingung, wodurch Kleinteile sowie schwere Werkstücke entgratet, poliert, entzundert, mattiert, geschliffen oder entfettet werden können.",

                "Zum Gleitschleifen benutzen wir einen R320 EC-Rundvibrator der Firma Rösler. In Verbindung mit einer Reinigungs- und Trocknungsanlage können wir Werkstücke vollautomatisiert bearbeiten.",
              ]}
              ipos="right"
              textTitle="Gleitschleifen"
              titleVariant="h4"
              img={_links.gleitschleifen}
              imgAlt="Gleitscheifen"
            />
            <LightBox
              className="flex wrap space-around"
              style={{ margin: "2rem 0 6rem 0" }}
              imgStyle={{
                maxHeight: breakpoints.xs * 0.6,
                margin: "5px",
              }}
              images={[
                {
                  src: _links.vorher_nachher,
                  alt: "Vorher Nacher Vergleich",
                },
              ]}
            />
          </section>
        </section>
      </div>
    </>
  )
}

export default Oberflaechenvorbehandlung
