import React from 'react'
import WithTopSlider from '../../templates/with-top-slider'
import { Helmet } from "react-helmet"
import { genTitle } from '../../../../helper/util'
import LightBox from "../../../../modules/LightBoxContainer"

const videos = [
  "/video/Cut/Trubend-Cut-compressed24.m4v",
  "/video/Cut/Trubend2-Cut-compressed24.m4v",
]

const Umformtechnik = () => {
  return (
    <>
    <Helmet>
      <title> {genTitle("Umformtechnik")} </title>
    </Helmet>

    <WithTopSlider items={videos} />
    <div id="Umformtechnik">
      <p>
      Durch den Einsatz modernster Umformtechnik mit CNC Steuerung wird in unserer Produktion eine exakte Qualität, mit geringsten Toleranzen sowie eine materialschonende Verarbeitung ermöglicht.
      </p>
      <table className="Nested-Table fullwidth Paper">
        <thead>
          <tr>
            <th> Maschine </th>
            <th> Format </th>
            <th> Material </th>
            <th> Dicke </th>
          </tr>
        </thead>
        <tbody>
          <tr className="Leading-Row">
            <td className="Nested-Cell"> Safan </td>
            <td className="Leading-Cell" style={{ verticalAlign: "middle" }} rowSpan={4} > max. 3000 mm </td>
            <td className="Leading-Cell" style={{ verticalAlign: "middle" }} rowSpan={4} > alle Blecharten<br/>je nach Stärke / Güte </td>
            <td className="Leading-Cell" style={{ verticalAlign: "middle" }} rowSpan={4} > 0,5 mm bis 25 mm </td>
          </tr>
          <tr className="Nested-Row">
            <td> TruBend 5320 </td>
          </tr>
          <tr className="Nested-Row">
            <td> TruBend 5230 </td>
          </tr>
          <tr className="Nested-Row">
            <td> Trumabend V230 </td>
          </tr>
        </tbody>
      </table>
      <LightBox  images={[{ src: "/image/Umformtechnik.JPG", alt: "Umformtechnik" }]} />
    </div>
    </>
  )
}

export default React.memo(Umformtechnik)
