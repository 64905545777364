import React from 'react'
import { Helmet } from "react-helmet"

const PageNotFound = () => {
  return (
    <>
    <Helmet>
      <title>Seite nicht gefunden!</title>
    </Helmet>

    <div className="flex center">
      <h1>404 - Seite nicht gefunden!</h1>
    </div>
    </>
  )
}

export default PageNotFound
