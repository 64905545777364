import React from 'react'
import { Helmet } from "react-helmet"
import { genTitle } from '../../../../helper/util'

const Impressum = () => {
    return (
        <div id="Impressum">
            <Helmet>
                <title> {genTitle("Impressum")} </title>
            </Helmet>

            <address style={{ fontStyle: "normal", textAlign: "start" }} >
                KWL Blechverarbeitung GmbH<br/>
                Industriegebiet Distelfeld<br/>
                Breslauer Strasse 66<br/>
                56566 Neuwied<br/>
                Telefon: 0 26 31 - 95 56-0<br/>
                Telefax: 0 26 31 - 95 56 66<br/>
                E-mail: info@kwl-blech.de
            </address>
                <p>
                    Registergericht:<br/> 
                    Amtsgericht Neuwied</p>
                <p>
                    Registernummer:<br/> 
                    HRB 14085
                </p>

                <p>
                    Geschäftsführer:<br/> 
                    René Krapf, Christoph Andes, Martin Lorsbach
                </p>
                <p>
                    Inhaltlich Verantwortlicher gemäß § 6 MDStV:<br/> 
                    KWL Blechverarbeitung GmbH
                </p>
                <p>
                    USt-Id.Nr gemäß § 27 a UStG:<br/>
                    DE 176743918
                </p>
                <p>
                    Steuernummer:<br/>
                    32/660/1536/5
                </p>
        </div>
    )
}

export default Impressum
