import React from 'react'
import { Helmet } from "react-helmet"
import { genTitle } from '../../../../helper/util'

const Montage = () => {
  return (
    <div id="Montage">
      <Helmet>
        <title> {genTitle("Montage")} </title>
      </Helmet>

      <p>Als Systemlieferant können wir auch die Montage Ihrer Bauteile für Sie übernehmen. Die Vorteile dieser Komplettlösung liegen auf der Hand:</p>
      <p>Sie sparen Zeit und Kosten und können sich auf ihr Kerngeschäft konzentrieren.</p>
    </div>
  )
}

export default Montage
