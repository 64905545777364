import React, { useMemo, useEffect } from "react"
import "./style.scss"
import PDF from "../../../../modules/pdf_viewer"
import { PDFFileRef } from "../../../../modules/pdf_viewer"
import { useParams, Switch, Route, NavLink, useHistory } from "react-router-dom"

type PDFTemplateProps = {
    items: PDFFileRef[]
}
type Params = {
    current: string
}
const PDFTemplate: React.FC<PDFTemplateProps> = ({ items }) => {
    const history = useHistory()
    const { current } = useParams<Params>()

    const base_url = useMemo(() => {
        const a = history.location.pathname.split("/")
        return a.slice(0, a.length - 1).join("/")
    }, [history.location.pathname])

    useEffect(() => {
        if (current || items.length < 2) return
        const next = `${history.location.pathname}/${items[0].title}`
        history.replace(next)
    }, [current, history, items])

    if (items.length === 0) return null
    return (
        <div className="pdf-template">
            {items.length > 1 ? (
                <>
                    <div className="tab-controls flex wrap center">
                        {items.map(({ title, subtitle }, i) => (
                            // <button className={"button margin-v-1 margin-vh-1"+(current===title?" active":"")} key={title} onClick={() => setIndex(i)} type="button">{title}</button>
                            <NavLink
                                key={title}
                                className={`button margin-v-1 margin-vh-1`}
                                to={base_url + "/" + title}
                            >
                                <h4> {title} </h4>
                                {subtitle && <h5> {subtitle} </h5>}
                            </NavLink>
                        ))}
                    </div>
                    <div className="tabs">
                        <Switch>
                            {items.map((item, i) => (
                                <Route
                                    key={item.title}
                                    exact
                                    path={base_url + "/" + item.title}
                                >
                                    <PDF {...item} key={item.title} />
                                </Route>
                            ))}
                            <Route
                                exact
                                render={() => <h1>PDF not found!</h1>}
                            />
                        </Switch>
                    </div>
                </>
            ) : (
                <PDF {...items[0]} />
            )}
        </div>
    )
}

export default PDFTemplate
