export const DateFormatter = new Intl.DateTimeFormat("de", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
})
export function formatDate(
  date: string | number | Date | null | undefined
): string {
  if (date === null || date === undefined) return ""
  const d = new Date(date)
  // check for valid date
  if (Number.isNaN(d.getTime())) return ""
  return DateFormatter.format(d)
}
