import React from "react"
import lastertabelle from "../../../../context/data/lastertabelle.json"
import WithTopSlider from "../../templates/with-top-slider"
import { Helmet } from "react-helmet"
import { genTitle } from "../../../../helper/util"

const { data } = lastertabelle
const videos = [
  "/video/Cut/Liftmaster-Cut-compressed26.m4v",
  "/video/Cut/Trumatic-Cut-compressed26.m4v",
  "/video/Cut/Trulaser-Marked-2X-Cut-compressed27.m4v",
]

const CNC_Laserschneiden = () => {
  return (
    <>
      <Helmet>
        <title> {genTitle("CNC Laserschneiden")} </title>
      </Helmet>

      <WithTopSlider items={videos} />
      <div id="CNC_Laserschneiden">
        <p>
          <strong>Blech und alles was wir daraus machen,</strong> ganz gleich
          welche Materialgüte bzw. welcher Fertigungsprozess angewendet wird,
          wir setzen in unserer Blechverarbeitung auf einen breit aufgestellten
          TRUMPF Maschinenpark.
        </p>
        <table className="Nested-Table fullwidth Paper">
          <thead>
            <tr>
              <th> Maschine </th>
              <th> Format </th>
              <th> Material </th>
              <th> Dicke </th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => (
              <React.Fragment key={i}>
                <tr className="Leading-Row" style={{ margin: "1em 0" }}>
                  <td
                    style={{ verticalAlign: "top" }}
                    rowSpan={d.Material_Dicke.length}
                    className="Leading-Cell"
                  >
                    {" "}
                    {d.Maschine}{" "}
                  </td>
                  <td
                    style={{ verticalAlign: "top" }}
                    rowSpan={d.Material_Dicke.length}
                    className="Leading-Cell"
                  >
                    {" "}
                    {d.Format}{" "}
                  </td>
                  <td className="Nested-Cell"> {d.Material_Dicke[0][0]} </td>
                  <td className="Nested-Cell"> {d.Material_Dicke[0][1]} </td>
                </tr>
                {d.Material_Dicke.slice(1).map(([material, dicke], j) => (
                  <tr className="Nested-Row" key={j}>
                    <td className="Nested-Cell"> {material} </td>
                    <td className="Nested-Cell"> {dicke} </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <img
          src="/image/Laser-Halle-4.JPG"
          alt="laser halle 4"
          style={{ margin: "1em auto" }}
        />
      </div>
    </>
  )
}

export default React.memo(CNC_Laserschneiden)
