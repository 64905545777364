import React, { CSSProperties } from 'react'
import "./style.scss"

type SeparatorProps = {
  mode?: "vertical" | "horizontal"
  style?: CSSProperties
  className?: string
  children?: (string | React.ReactElement)[]
}

const Separator: React.FC<SeparatorProps> = ({ children, className="", mode="vertical", style={} }) => {
  return (
    <div className={`Separator ${mode} ${className}`} style={style}>
      {children && children}
    </div>
  )
}

export default Separator
