import React, { useMemo } from "react"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import LightBox from "../LightBoxContainer"
import "./style.scss"
import { useWebP } from "../../helper/hooks"
import { type Variant as TextVariant } from "@mui/material/styles/createTypography"

const Paragraph = ({ text }: { text: string }) => (
  <Typography paragraph variant="inherit">
    {text}
  </Typography>
)

const ImageText: React.FC<ImageTextProps> = ({
  img,
  imgAlt,
  text,
  textTitle,
  titleVariant = "h4",
  spacing = { img: 5, text: 7 },
  gap = 6,
  ipos = "right",
}) => {
  const img_src = useWebP(img) as string

  const Img = useMemo(
    () => (
      <Grid item xs={12} lg={spacing.img}>
        <div className="center">
          <LightBox images={[{ src: img_src, alt: imgAlt }]} />
        </div>
      </Grid>
    ),
    [img_src, imgAlt, spacing.img]
  )

  return (
    <Grid
      container
      alignContent="space-between"
      justifyContent="space-around"
      spacing={gap}
      className="ImageText"
      wrap={ipos === "right" ? "wrap" : "wrap-reverse"}
    >
      {ipos === "left" && Img}
      <Grid item xs={12} lg={spacing.text}>
        <div className="center">
          {textTitle && (
            <Typography variant={titleVariant}>{textTitle}</Typography>
          )}
          {Array.isArray(text) ? (
            text.map((t, i) => <Paragraph key={i} text={t} />)
          ) : (
            <Paragraph text={text} />
          )}
        </div>
      </Grid>
      {ipos === "right" && Img}
    </Grid>
  )
}
export default ImageText

type Spacing =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 12
  | boolean
  | "auto"
  | undefined
type ImageTextProps = {
  img: string
  imgAlt: string
  text: string | string[]
  textTitle?: string
  titleVariant?: TextVariant
  spacing?: {
    img: Spacing
    text: Spacing
  }
  ipos?: "left" | "right"
  gap?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
}
