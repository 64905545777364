import React from 'react'
import { NavigationProps, MenuItemType } from "./Navigation"
import { NavLink } from 'react-router-dom'
import Dropdown from '../../modules/dropdown'


const DesktopNavigationItem: React.FC<MenuItemType> = ({ title, to, children }) => {
  return children.length === 0
    ? <NavLink exact to={to} className="undecorated" >{title}</NavLink>
    : (
      <Dropdown hover As={props => <div {...props}></div>}>
        {{
          Head: <NavLink to={to} className="undecorated" >{title}</NavLink>,
          Tail: children.map((item, i) => <DesktopNavigationItem key={i} {...item} />)
        }}
      </Dropdown>
    )
}

const DesktopNavigation: React.FC<NavigationProps> = ({ items }) => {
  return (
    <nav className="wide">
      <ul className="undecorated flex" >
        {items.map((item, i) => <li key={i} className="undecorated"><DesktopNavigationItem key={i} {...item} /></li>)}
      </ul>
    </nav>
  )
}

export default DesktopNavigation
