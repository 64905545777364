import React from 'react'
import { NavLink } from 'react-router-dom'
import zertifikate from "../../../../context/data/zertifikate.json"
import WithTopSlider from '../../templates/with-top-slider'
import { Helmet } from "react-helmet"
import { genTitle } from '../../../../helper/util'
import LightBox from "../../../../modules/LightBoxContainer"

const videos = [
  "/video/Cut/Handschweissen-Cut-compressed28.m4v",
  "/video/Cut/Roboterschweissen-Cut-Marked-4X-compressed26.m4v",
]

const Schweisstechnik = () => {
  return (
    <>
    <Helmet>
      <title> {genTitle("Schweißtechnik")} </title>
    </Helmet>

    <WithTopSlider items={videos} />
    <div id="Schweisstechnik">
      <p>Das Team unserer Schweißerei zeichnet sich durch aus jahrelanger Erfahrung erwachsenem Know-how aus, was wir natürlich durch die entsprechenden Schweißfachprüfungen nachweisen können. Auf sämtliche Herausforderungen aus Stahl, Edelstahl oder Aluminium finden wir eine Lösung, die automatisiert oder von Hand umgesetzt werden kann. </p>
      <p>Wir sind ein anerkannter Schweißfachbetrieb nach <NavLink to={`/Unternehmen/Zertifizierungen/${zertifikate[2].title}` }>DIN EN 1090-2 EXC1/EXC2</NavLink>  mit ausgebildetem Schweißpersonal nach ISO 9606-1, Prüfpersonal für PT und VT, sowie Schweißaufsichtspersonal IWT und IWS.</p>
      <p>Je nach Anspruch setzen wir die folgenden Schweißverfahren ein:</p>
      <ul>
        <li>WIG-Schweißen</li>
        <li>MIG / MAG – Schweißen </li>
        <li>Roboterschweißen</li>
        <li>Punktschweißen</li>
      </ul>
      <LightBox  images={[{ src: "/image/Schweißtechnik.JPG", alt: "Schweißtechnik" }]} />
    </div>
    </>
  )
}

export default Schweisstechnik
