import React from 'react'
import Download, { DownloadTypes } from '../download'
import "./style.scss"

type PDFProps = {
    src: string
    display?: boolean
    pages?: number
    title: string
    subtitle?: string
}

const PDF: React.FC<PDFProps> = ({ title, subtitle,  src, display=true, pages=1 }) => {
    return (
        <div className={"pdf flex column center" + (display?"":" hidden")}>
            <div className="pdf-topbar flex row space-between fullwidth align-center wrap"> 
                <div className="pdf-title" >
                    <h2> {title} </h2>
                    {subtitle && <h4> {subtitle} </h4>}
                </div>
                {/* <Download src={"/pdf"+src} type={DownloadTypes.pdf}>Download</Download> */}
            </div>
            <div className="pdf-pages flex center wrap fullwidth">
                <object data={src} type="application/pdf" width="100%" height="100%" >
                    <Download src={src} type={DownloadTypes.pdf}>Download</Download>
                    {/* {Array(pages).fill(1).map((v,i) => {
                        const folder = src.slice(4,src.length-4)
                        const image_src = `/pdf/images/${folder}/${i}.png`
                        return <img key={i} src={image_src} alt={folder} />
                    })} */}
                </object>
            </div>
        </div>
    )
}

export default React.memo(PDF)


export type PDFFileRef = {
    title: string
    subtitle?: string
    src: string
    pages?: number
}