export function modulo(x: number, n: number) {
  return ((x%n)+n)%n 
}

export function orZero(x: any): number {
  if (typeof x !== "number") return 0
  if (Number.isNaN(x) || !Number.isFinite(x)) return 0
  return x
}

export function canIndex(x: any): boolean {
  if (
    typeof x !== "number"
    || x < 0
    || Math.floor(x) !== x 
  ) return false
  return true
}