import React from 'react'
import PDFTemplate from '../../templates/pdf-template'
import items from "../../../../context/data/agb.json"
import { Helmet } from "react-helmet"
import { genTitle } from '../../../../helper/util'

const AGB = () => {
    return (
        <>
        <Helmet>
            <title> {genTitle("AGB")} </title>
        </Helmet>

        <PDFTemplate items={items} />
        </>
    )
}

export default AGB
