import React from "react"
import { Switch, Route, Redirect, SwitchProps } from "react-router-dom"
import FrontPage from "./pages/frontpage"
import CNC_Laserschneiden from "./pages/cnc_laserschneiden"
import PageNotFound from "./pages/404"
import Kontakt from "./pages/kontakt"
import Zertifikate from "./pages/zertifikate"
import Ansprechpartner from "./pages/Ansprechpartner"
import Datenschutz from "./pages/datenschutz"
import AGB from "./pages/agb"
import Stellenangebote from "./pages/stellenangebote"
import Umformtechnik from "./pages/umformtechnik"
import MechanischeBearbeitung from "./pages/mechanische bearbeitung"
import Schweisstechnik from "./pages/schweißtechnik"
import Oberflaechentechnik from "./pages/oberflächentechnik"
import Oberflaechenvorbehandlung from "./pages/oberflächenvorbehandlung"
import Montage from "./pages/montage"
import Konstruktion from "./pages/konstruktion"
import Logistik from "./pages/logistik"
import Impressum from "./pages/impressum"
import Rohrlaser from "./pages/rohrlaser"
import History from "./pages/über_uns"
import Produktionsverfahren from "./pages/produktionsverfahren"
import Dienstleistungen from "./pages/dienstleistungen"
import Unternehmen from "./pages/unternehmen"
import QS from "./pages/qualitätssicherung"
import { ENABLE_STELLENANGEBOTE } from "../header"

interface RouterProps {
  location: SwitchProps["location"]
}

const Router: React.FC<RouterProps> = ({ location }) => {
  return (
    <section>
      <Switch location={location}>
        <Route exact path="/" component={FrontPage} />
        <Route exact path="/Start" render={() => <Redirect to="/" />} />
        <Route exact path="/Home" render={() => <Redirect to="/" />} />
        <Route
          exact
          path="/Produktionsverfahren"
          component={Produktionsverfahren}
        />
        <Route
          exact
          path="/Produktionsverfahren/Blechbearbeitung"
          render={() => <Redirect to="/Produktionsverfahren" />}
        />
        <Route
          exact
          path="/Produktionsverfahren/CNC Laserschneiden"
          component={CNC_Laserschneiden}
        />
        <Route
          exact
          path="/Produktionsverfahren/Rohrlaser"
          component={Rohrlaser}
        />
        <Route
          exact
          path="/Produktionsverfahren/Umformtechnik"
          component={Umformtechnik}
        />
        <Route
          exact
          path="/Produktionsverfahren/Mechanische Bearbeitung"
          component={MechanischeBearbeitung}
        />
        <Route
          exact
          path="/Produktionsverfahren/Schweißtechnik"
          component={Schweisstechnik}
        />
        <Route
          exact
          path="/Produktionsverfahren/Schweissen"
          render={() => <Redirect to="/Produktionsverfahren/Schweißtechnik" />}
        />
        <Route
          exact
          path="/Produktionsverfahren/Oberflächentechnik"
          component={Oberflaechentechnik}
        />
        <Route
          exact
          path="/Produktionsverfahren/Oberflächenvorbehandlung"
          component={Oberflaechenvorbehandlung}
        />
        <Route exact path="/Produktionsverfahren/Montage" component={Montage} />
        <Route exact path="/Dienstleistungen" component={Dienstleistungen} />
        <Route
          exact
          path="/Dienstleistungen/Konstruktion"
          component={Konstruktion}
        />
        <Route exact path="/Dienstleistungen/Logistik" component={Logistik} />
        <Route
          exact
          path="/Dienstleistungen/Qualitätssicherung"
          component={QS}
        />
        <Route exact path="/Unternehmen" component={Unternehmen} />
        <Route
          exact
          path="/Unternehmen/Ansprechpartner"
          component={Ansprechpartner}
        />
        {ENABLE_STELLENANGEBOTE && (
          <Route
            exact
            path="/Stellenangebote/:jobname?"
            component={Stellenangebote}
          />
        )}
        <Route exact path="/Unternehmen/Über uns" component={History} />
        <Route exact path="/Kontakt" component={Kontakt} />
        <Route
          exact
          path="/Unternehmen/Zertifizierungen/:current?"
          component={Zertifikate}
        />
        <Route exact path="/Datenschutz/:current?" component={Datenschutz} />
        <Route exact path="/AGBs/:current?" component={AGB} />
        <Route exact path="/Impressum" component={Impressum} />
        <Route component={PageNotFound} />
      </Switch>
    </section>
  )
}

export default Router
