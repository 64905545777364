import React from 'react'
import WithTopSlider from '../../templates/with-top-slider'
import { genTitle } from '../../../../helper/util'
import { Helmet } from "react-helmet"
import LightBox from "../../../../modules/LightBoxContainer"

const videos = [
  "/video/Cut/Dmu2-Cut-Marked-3X-compressed28.m4v",
  "/video/Cut/Dmf_260_7-Cut-Marked-4X-compressed26.m4v",
  "/video/Cut/Dmf_260_11-Cut-Marked-1X-1X-8X-compressed28.m4v",
]

const MechanischeBearbeitung = () => {
  return (
    <>
    <Helmet>
      <title> {genTitle("Mechanische Verarbeitung")} </title>
    </Helmet>

    <WithTopSlider items={videos} />
    <div id="MechanischeBearbeitung">
      <p>Moderne CNC-Bearbeitungszentren ermöglichen in Kombination mit unseren qualifizierten Mitarbeitern eine breite Vielfalt an Fertigungsmöglichkeiten. Unser Produktportfolio erstreckt sich von der Bearbeitung einfacher Zuschnitte bis hin zu komplexen Schweißbaugruppen. Aufgrund unseres CAD-CAM-Verbund stellen komplexe Bauteilgeometrien für uns kein Problem dar. </p>
      <p>Wir fertigen vom Prototyp bis hin zur Serie, Bauteile aus den verschiedensten Materialien. Gestützt wird die Produktion zusätzlich durch eine manuelle Fertigung in den Bereichen Sägen, Bohren, Senken und Gewindeschneiden. Hierfür stehen mehrere Arbeitsplätze mit geschultem Personal zur Verfügung.</p>
      <table className="Nested-Table Paper fullwidth">
        <thead>
          <tr>
            <th> Maschine </th>
            <th> Format </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> DMF 260-7 linear </td>
            <td> 2600 x 700 x 600 mm </td>
          </tr>
          <tr>
            <td> DMF 260-11 linear (5-Achs-Fräsen) </td>
            <td> 	2600 x 1100 x 920 mm </td>
          </tr>
          <tr>
            <td> DMC 635 V </td>
            <td>	635 x 510 x 460 mm </td>
          </tr>
          <tr>
            <td> DMU 75 monoBLOCK (5-Achs-Fräsen) </td>
            <td> 	750 x 650 x 560 mm </td>
          </tr>
          <tr>
            <td> CTX 410 </td>
            <td> Ø 280 mm x 600 mm </td>
          </tr>
          <tr>
            <td> CTX 310 + 3m Stangenlader </td>
            <td> Ø 50 mm x 415 mm </td>
          </tr>
          <tr>
            <td> KastoTwin AE4 Bandsäge	</td>
            <td> 400 x 400 mm	</td>
          </tr>
        </tbody>
      </table>
      <LightBox images={[{ src: "/image/Zerspanung.JPG", alt: "Zerspanung" }]} />
    </div>
    </>
  )
}

export default MechanischeBearbeitung
