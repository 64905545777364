import React, { useMemo } from "react"
import { NavLink, useLocation } from "react-router-dom"
import "./style.scss"

type BreadcrumbsProps = {}

function addWhiteSpaceBetweenSnakes(s: string): string {
  return s.replace(/([a-züöäß])([A-ZÜÖÄẞ])/g, "$1 $2")
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = () => {
  const { pathname } = useLocation()
  const [crumbs, visuals]: [string[], string[]] = useMemo(() => {
    const paths = pathname
      .split("/")
      .slice(1)
      .filter(sl => sl.length > 0)
    const visuals = paths.map(s => addWhiteSpaceBetweenSnakes(s))
    return [paths, visuals]
  }, [pathname])

  if (pathname === "/") return null
  return (
    <>
      <div className="Breadcrumbs flex wrap">
        {visuals
          .map((v, i) => (
            <span key={i} className="Breadcrumbs-Crumb">
              <NavLink
                className="color-1"
                to={"/" + crumbs.slice(0, i + 1).join("/")}
              >
                {v}
              </NavLink>
            </span>
          ))
          .reverse()}
      </div>
      <div className="header-fix" />
    </>
  )
}

export default Breadcrumbs
