import React, { CSSProperties } from "react"
import "./style.scss"

export enum DownloadTypes {
  none = "",
  pdf = "pdf",
}

type DownloadProps = React.PropsWithChildren<{
  src: string
  style?: CSSProperties
  className?: string
  type?: DownloadTypes
}>

const Download: React.FC<DownloadProps> = ({
  src,
  children,
  style = {},
  className = "",
  type,
}) => {
  return (
    <a
      href={src}
      download
      className={[`Download${type ? "-" + type : ""}`, className].join(" ")}
      style={style}
    >
      {/* Relies on correct file names */}
      {type === DownloadTypes.pdf && (
        <img src={`/image/logo/${type}.svg`} alt={type} />
      )}
      {children}
    </a>
  )
}

export default Download
