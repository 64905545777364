import { useMediaQuery } from "@mui/material"
import { useEffect, useMemo, useRef, useState } from "react"

export function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => void (ref.current = value), [value])
  return ref.current
}

export function useToggle(
  initialValue = false
): [boolean, () => void, (newState: boolean) => void] {
  const [state, setState] = useState(initialValue)
  const toggle = () => setState(v => !v)
  const set = (newState: boolean) => setState(newState)
  return [state, toggle, set]
}

export function useISIE(): boolean {
  const ie = useMediaQuery(
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)"
  )
  return ie
}

export function useWebP(img_src?: string | string[]) {
  const ie = useISIE()
  const src = useMemo(() => {
    if (img_src === undefined) return undefined
    return ie
      ? img_src
      : Array.isArray(img_src)
      ? img_src.map(src => src.replace(/\.\w+$/, ".webp"))
      : img_src.replace(/\.\w+$/, ".webp")
  }, [ie, img_src])
  return src
}
