import React from 'react'

type AdressProps = {
  name?: string
  street?: string 
  code?: number | string 
  city?: string
  tel?: string
  fax?: string 
  email?: string
  className?: string
}

const Adress: React.FC<AdressProps> = ({ className, name, street, code, city, tel, fax, email }) => {
  return (
    <address className={className && className} >
      {name && <span> {name} </span>}
      {street && <span> {street} </span>}
      {city && <span> {code && code} {city} </span>}
      {tel && <span>Tel: {tel} </span>}
      {fax && <span>Fax: {fax} </span>}
      {email && <span><a href={`mailto:${email}`} >{email}</a></span>}
    </address>
  )
}

export default Adress
