import React from "react"
import DesktopNavigation from "./DesktopNavigation"
import MobileNavigation from "./MobileNavigation"
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery"
import { MENU_RESPONSIVE_QUERY } from "../../context/theme/constants"

export type MenuItemType = {
  title: string
  to: string
  children: MenuItemType[]
}
export type NavigationProps = {
  items: MenuItemType[]
}

const Navigation: React.FC<NavigationProps> = props => {
  const showFull = useMediaQuery(MENU_RESPONSIVE_QUERY)

  return (
    <>
      {showFull ? (
        <DesktopNavigation {...props} />
      ) : (
        <MobileNavigation {...props} />
      )}
    </>
  )
}

export default Navigation
