import React, { MouseEvent, useCallback } from "react"
import "./style.scss"

interface BurgerProps {
  onClick: () => any
  isOpen: boolean
}
const Burger: React.FC<BurgerProps> = ({ onClick, isOpen }) => {
  const _onClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      onClick()
    },
    [onClick]
  )

  return (
    <button
      className={`hamburger hamburger--emphatic ${isOpen ? "is-active" : ""}`}
      type="button"
      onClick={_onClick}
      aria-label="open menu"
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  )
}

export default Burger
