import React from 'react'
import LinkCard from '../../../../modules/LinkCard/LinkCard'

type NavPageProps = {
    pages: {
        title: string
        to: string
        img?: string
        imgPosition?: string 
    }[]
}

const NavPage: React.FC<NavPageProps> = ({ pages }) => {
    return (
        <div className="NavPage flex center wrap">
            {pages.map(({ title, to, img, imgPosition="center" }, i) => (
                <LinkCard
                    style={{ margin: "1rem" }}
                    key={title}
                    title={title}
                    img={img}
                    to={to}
                    imgPosition={imgPosition}
                />
            ))}
        </div>
    )
}

export default React.memo(NavPage)