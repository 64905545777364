import React from 'react'
import { NavLink } from 'react-router-dom'
import zertifikate from "../../../../context/data/zertifikate.json"
import { Helmet } from "react-helmet"
import { genTitle } from '../../../../helper/util'

const QS = () => {
    return (
        <div id="QS">
            <Helmet>
                <title> {genTitle("Qualitätssicherung")} </title>
            </Helmet>

            <p>Zur Sicherstellung von höchster Qualität und eines reibungslosen Fertigungsablaufes wurde in der KWL Blechverarbeitung GmbH bereits vor mehr als 20 Jahren ein interner Qualitätssicherungsprozess eingeführt. Geprüfte Qualitätsfachmänner und -frauen der Fertigungsprüftechnik sorgen für eine lückenlose Überwachung und Einhaltung der Anforderungen unserer Kunden. Auf Wunsch führen wir Erstmusterprüfungen durch, Erstellen schriftliche Prüfberichte und bieten Abnahmeprüfzeugnisse (APZ 3.1) über die verwendeten Materialien an.</p>
            <p>Seit 1999 ist unser Qualitätsmanagementsystem nach <NavLink to={`/Unternehmen/Zertifizierungen/${zertifikate[0].title}` }>DIN EN ISO 9001:2015</NavLink> zertifiziert. Im Rahmen von wiederkehrenden Audits bekennen wir uns jährlich zu bestmöglicher Qualität und Effizienz, bei einem minimalen Fehlerrisiko.</p>
            <p>Auch die Erhaltung der Umwelt ist ein Thema, das in der KWL Blechverarbeitung großgeschrieben wird. Durch eine nachhaltige und ressourcenschonende Produktion möchten wir unseren Beitrag zur Bekämpfung des Klimawandels leisten. Seit 2014 sind wir daher auch nach <NavLink to={`/Unternehmen/Zertifizierungen/${zertifikate[1].title}` }>DIN EN ISO 14001:2015</NavLink> zertifiziert.</p> 
        </div>
    )
}

export default QS
