import React from 'react'
import WithTopSlider from '../../templates/with-top-slider'
import { Helmet } from "react-helmet"
import { genTitle } from '../../../../helper/util'

const videos = [
    "/video/Cut/Tube7000-Cut-Marked-4X-compressed28.m4v",
]

const Rohrlaser = () => {
    return (
        <>
        <Helmet>
            <title> {genTitle("Rohrlaser")} </title>  
        </Helmet>

        <WithTopSlider items={videos} />
        <div id="Rohrlaser">
            <p>
                Ein Highlight - in unserem Maschinenpark ist die TRUMPF Tube 7000. Unsere 3D Laserbearbeitung von Rohren jeglicher Kontur, sowie die Bearbeitung von Winkel-  und U- Profilen setzt völlig neue Maßstäbe hinsichtlich Konstruktionsmöglichkeiten und Fertigungszeit.
            </p>
            <table className="Nested-Table Paper fullwidth">
                <thead>
                <tr>
                    <th> Maschine </th>
                    <th> Format </th>
                    <th> Material </th>
                    <th> Dicke </th>
                </tr>
                </thead>
                <tbody>
                    <tr className="Leading-Row">
                        <td className="Leading-Cell" rowSpan={3}> TruLaser Tube7000 </td>
                        <td className="Leading-Cell" rowSpan={3}> 
                            Rohre/Profile<br/>
                            max. Hüllkreis Ø 250 mm<br/>
                            max. Länge 6500 mm<br/>
                            max. Metergewicht 37kg<br/>
                        </td>
                        <td className="Nested-Cell"> Stahl </td>
                        <td className="Nested-Cell"> bis 12 mm </td>
                    </tr>
                    <tr>
                        <td className="Nested-Cell"> Edelstahl </td>
                        <td className="Nested-Cell"> bis 6 mm </td>
                    </tr>
                    <tr>
                        <td className="Nested-Cell"> Aluminium </td>
                        <td className="Nested-Cell"> bis 4 mm </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </>
    )
}

export default Rohrlaser
