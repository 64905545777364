import React from "react"
import { Helmet } from "react-helmet"
import kwl_meta from "../../../../context/data/general.json"
import { genTitle } from "../../../../helper/util"
import LinkCard from "../../../../modules/LinkCard/LinkCard"
import Map from "../../../../modules/maps"
import Separator from "../../../../modules/separator"
import "./style.scss"

const { contact } = kwl_meta
let { name, street, code, city, tel, fax, email, open } = contact

const Kontakt: React.FC = () => {
  return (
    <div id="Kontakt" className="flex column space-evenly">
      <Helmet>
        <title> {genTitle("Kontakt")} </title>
        <meta
          name="description"
          content={`Telefon: ${contact.tel}, Email: ${contact.email}, Telefax ${contact.fax},  Karte unseres Standorts`}
        />
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
          integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
          crossOrigin="anonymous"
        />
      </Helmet>

      <h2>Kontakt</h2>
      <div className="flex center row wrap fullwidth space-between">
        <div className="margin-v-1 ws-no-break">
          <strong> {name} </strong>
          <br />
          {street} <br />
          {[code, city].join(" ")}
        </div>
        <div className="margin-v-1">
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Telefon</strong>
                </td>
                <td> {tel} </td>
              </tr>
              <tr>
                <td>
                  <strong>Telefax</strong>
                </td>
                <td> {fax} </td>
              </tr>
              <tr>
                <td>
                  <strong>Email</strong>
                </td>
                <td>
                  <a href={`mailto:${email}`}> {email} </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="margin-v-1">
          <table>
            <thead>
              <tr>
                <th colSpan={2}>Geschäftszeiten</th>
              </tr>
            </thead>
            <tbody>
              {open.map((t, i) => {
                const [days, time] = t.split(",")
                return (
                  <React.Fragment key={i}>
                    <tr>
                      <td> {days} </td>
                      <td> {time} </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      <Separator mode="horizontal"></Separator>
      <LinkCard
        style={{ marginTop: "1em", width: "100%", height: "400px" }}
        img="/image/Kontakt.jpg"
        to="/Unternehmen/Ansprechpartner"
        title="Ansprechpartner"
      />

      <Separator mode="horizontal"></Separator>
      <h2 style={{ textTransform: "uppercase", fontWeight: "normal" }}>
        Ihr <strong>Weg</strong> zu uns
      </h2>

      <Map />
      <div className="Wegbeschreibung">
        <div>
          <h2>Westlich von der A61 kommend</h2>
          <p>
            Auf der A61 fahrend, verlassen Sie die A61 am Autobahnkreuz 37 –
            Kreuz Koblenz in Richtung Koblenz. Nun folgen Sie der A48 für ca.
            6,5km bis Sie an der Ausfahrt 10 (Koblenz Nord) auf die B9 in
            Richtung Andernach abbiegen. Der B9 folgen Sie für ca. 8,5km bis Sie
            zur Abfahrt Altenkirchen/Neuwied/Weißenthurm kommen. An dieser
            Abfahrt fahren Sie auf die B256. Der B256 folgen Sie weitere 5km bis
            zur Ausfahrt NR-Beringstraße/NR-Friedrichshof/NR-Distelfeld. Am Ende
            dieser Ausfahrt biegen Sie rechts auf die Allensteiner Straße ab und
            anschließend biegen Sie an der nächsten Ampel direkt wieder rechts
            in die Breslauer Straße ab. Dieser folgen Sie nun knapp 400m. Die
            Firma KWL Blechverarbeitung befindet sich 100m nach dem Kreisel auf
            der linken Seite.
          </p>
        </div>

        <div>
          <h2>Östlich von der A3 kommend</h2>
          <p>
            Auf der A3 fahrend, verlassen Sie die A3 am Autobahnkreuz 39 – Kreuz
            Dernbach in Richtung Koblenz/Trier. Nun folgen Sie der A48 für ca.
            15km bis Sie sich an der Ausfahrt 11 – Bendorf/Neuwied auf die B42
            in Richtung Neuwied einfädeln. Der B42 folgen Sie ca. 7km bis zur
            Ausfahrt NR-Friedrichshof/NR-Distelfeld. Am Ende dieser Ausfahrt
            biegen Sie rechts ab auf die Rudolf-Diesel Straße. Der Rudolf-Diesel
            Straße folgen Sie knapp 200m. Am Ende der Straße biegen Sie rechts
            ab auf die Rostocker Straße. Der Rostocker Straße folgen Sie knapp
            800m bis Sie direkt nach einer langegezogenen Rechtskurve links in
            die Marienstraße abbiegen. Dieser Straße folgen Sie weitere 800m
            (nach der Rechtskurve wechselt der Name der Straße zu Stettiner
            Straße). Nach den 800m kommen Sie an einen Kreisel, den Sie an der
            dritten Ausfahrt in die Breslauer Straße verlassen. Die Firma KWL
            Blechverarbeitung befindet sich nach 100m auf der linken Seite.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Kontakt

// interface FormTypes {
//     vorname: { value: string }
//     nachname: { value: string }
//     unternehmen: { value: string }
//     email: { value: string }
//     tel: { value: string }
//     topic: { value: string }
//     content: { value: string }
// }
