import React from 'react'
import WithTopSlider from '../../templates/with-top-slider'
import { Helmet } from "react-helmet"
import { genTitle } from '../../../../helper/util'

const videos = [
  "/video/Cut/Otstory-Cut-compressed26.m4v"
]

const Oberflaechentechnik = () => {
  return (
    <>
    <Helmet>
      <title> {genTitle("Oberflächentechnik")} </title>
    </Helmet>

    <WithTopSlider items={videos} />
      <div id="Oberflaechentechnik">
        <p>Die Vorbereitung mit einem Strahlmittel dient der Anrauung der Oberfläche, dem Entrosten, dem Entfernen von Beschichtungen oder als Veredelung. In der KWL Oberflächentechnik werden all diese Arbeiten, sowie das Entfetten und Phosphatieren und Kantenverrundung zusätzlich zum Pulverbeschichten in sämtlichen RAL Tönen vorgenommen. Neben dem Schutz vor Korrosion ist auch der Einsatz von optischen Gestaltungen wie Glatt-, Matt- oder Strukturlackierungen möglich. </p>
      </div>
    </>
  )
}

export default Oberflaechentechnik
