import React from "react"
import kwl_metadata from "../../context/data/general.json"
import {
  MapContainer,
  TileLayer,
  Marker,
  Polygon,
  Tooltip,
} from "react-leaflet"
//@ts-ignore
import { Icon } from "leaflet/src/layer/marker/Icon"
import Address from "../../layout/footer/Address"

const buildings: [number, number][] = [
  [50.433869, 7.490187],
  [50.434503, 7.490466],
  [50.434767, 7.491869],
  [50.433846, 7.492116],
  [50.433555, 7.492218],
  [50.432646, 7.492119],
  [50.432373, 7.491993],
  [50.432383, 7.491387],
  [50.433773, 7.49147],
  [50.43372, 7.490786],
]

const icon = new Icon({
  iconUrl: "/image/logo/marker.svg",
  iconSize: [64, 64],
  iconAnchor: [32, 64],
  popupAnchor: [0, -64],
  tooltipAnchor: [0, -64],
})

// Finish this when deployed
const Maps: React.FC = () => {
  const { maps, contact } = kwl_metadata
  return (
    <div className="fullsize">
      <MapContainer
        center={maps.center}
        zoom={maps.zoom}
        style={{ height: "600px" }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={maps.center} icon={icon}>
          <Tooltip direction="top">
            <Address
              className="flex column"
              name={contact.name}
              street={contact.street}
              code={contact.code}
              city={contact.city}
            />
          </Tooltip>
        </Marker>
        <Polygon positions={buildings} />
      </MapContainer>
    </div>
  )
}

export default Maps
