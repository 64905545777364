import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery"
import { breakpoints } from "../../context/theme/constants"

type ParentProps = {
  children: any
  up?: boolean
  down?: boolean
}

// type WidthSpan = {
//     maxWidth?: number
//     minWidth?: number
// }

export const DesktopWide: React.FC<ParentProps> = ({ children }) => {
  const isDesktopWide = useMediaQuery(`(min-width: ${breakpoints.l}px)`)
  return isDesktopWide ? children : null
}

export const Desktop: React.FC<ParentProps> = ({
  children,
  up = false,
  down = false,
}) => {
  const query = [
    !up ? `(max-width: ${breakpoints.l - 1}px)` : undefined,
    !down ? `(min-width: ${breakpoints.m}px)` : undefined,
  ]
    .filter(x => x)
    .join(" and ")
  const isDesktop = useMediaQuery(query)
  return isDesktop ? children : null
}

export const Mobile: React.FC<ParentProps> = ({ children }) => {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.m - 1}px)`)
  return isMobile ? children : null
}
