export const breakpoints = {
  xs: 376,
  s: 426,
  m: 769,
  l: 1025,
  xl: 1408
}

export const HEADER_HEIGHT = 64

export const MENU_RESPONSIVE_QUERY = `(min-width:75em)`

export const CALC_SLIDER_TOP_MARGIN_WITH_BREADCRUMBS = "calc(-64px - 2vh)"
export const CALC_SLIDER_TOP_MARGIN = "-2vh"

export const COLOR_1 = {
  background: "#202020",
  color: "#DDD"
}
export const COLOR_2 = {
  background: "#3A3A3A",
  color: "#DDD"
}
// export const COLOR_3 = {
//   background: "#007f3e",
//   color: "#DDD"
// }
// export const COLOR_4 = {
//   background: "rgb(42, 172, 83)",
//   color: "#DDD"
// }
export const COLOR_3 = {
  background: "#00A995",
  color: "#DDD"
}
export const COLOR_4 = {
  background: "#bee2e9",
  color: "#DDD"
}
export const COLOR_5 = {
  background: "#c6ddc9",
  color: "#DDD"
}

export const TITLE_BASE = "KWL Blech"

export const VIDEO_HEIGHT_TO_WIDTH = 752 / 1920;