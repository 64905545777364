import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import Mail from "@mui/icons-material/MailOutlineOutlined"
import Profile from "@mui/icons-material/PersonOutlineTwoTone"
import Phone from "@mui/icons-material/PhoneOutlined"
import React from "react"
import { useWebP } from "../../../../helper/hooks"

export type PersonProps = {
  name: string
  img?: string
  tel?: string
  mail?: string
  role?: string
  img_height?: number | "auto"
}

const Person: React.FC<PersonProps> = ({
  name,
  role,
  img_height = "auto",
  img = "/image/Ansprechpartner/placeholder.svg",
  tel,
  mail,
}) => {
  const img_src = useWebP(img) as string
  return (
    <Card className="Person-Card">
      <CardHeader
        title={role}
        titleTypographyProps={{
          noWrap: true,
          className: "Person-Header",
        }}
      />
      <CardMedia
        className="shadowed"
        style={{ height: "230px", objectPosition: "center 30%" }}
        component="div"
        image={img_src}
        title={`${name} - ${role}`}
      />
      <CardContent>
        <ul className="Person-Rows undecorated">
          <li>
            <Profile />
            <Typography noWrap>{name}</Typography>
          </li>
          {tel && (
            <li>
              <Phone />
              <Typography noWrap>{tel}</Typography>
            </li>
          )}
          {mail && (
            <li>
              <Mail />
              <Typography noWrap>
                <a href={`mailto:${mail}`}> {mail} </a>
              </Typography>
            </li>
          )}
        </ul>
      </CardContent>
    </Card>
  )
}

export default Person
