import React, { useEffect } from 'react'
import "./style.scss"
import { useLocation } from 'react-router-dom'
import Breadcrumbs from '../../modules/breadcrumbs'
import { Desktop, Mobile } from '../../modules/responsive'
import last from "lodash/last"
import { CALC_SLIDER_TOP_MARGIN } from '../../context/theme/constants'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import CSSTransition from 'react-transition-group/CSSTransition'
import Router from "./router"

const Main = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0,0)
  }, [location])

  return (
    <main style={{ display: "block" }}>
      <Desktop up> 
        <Breadcrumbs />
      </Desktop>
      {location.pathname !== "/" && (
        <Mobile>
          <h2 className="color-1" style={{ margin: 0, marginBottom: `calc(-1 * ${CALC_SLIDER_TOP_MARGIN})`, marginTop: CALC_SLIDER_TOP_MARGIN, padding: "1em", textAlign: "center" }}>
            {last(location.pathname.split("/"))}
          </h2>
        </Mobile>
      )}
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          timeout={{ enter: 500, exit: 0 }}
          classNames="fade"
          mountOnEnter
        >
          <Router location={location} />
        </CSSTransition>
      </TransitionGroup>

      {/* <Transition native
        items={location} 
        keys={item => item.pathname}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ display: "none" }}
        unique
      >
        {item => (props =>
          <animated.div style={props}>
            <Switch location={item}>
              <Route exact path="/" component={FrontPage} />
              <Route exact path="/Produktionsverfahren" component={Produktionsverfahren} />
              <Route exact path="/Produktionsverfahren/CNC Laserschneiden" component={CNC_Laserschneiden} />
              <Route exact path="/Produktionsverfahren/Rohrlaser" component={Rohrlaser} />
              <Route exact path="/Produktionsverfahren/Umformtechnik" component={Umformtechnik} />
              <Route exact path="/Produktionsverfahren/Mechanische Bearbeitung" component={MechanischeBearbeitung} />
              <Route exact path="/Produktionsverfahren/Schweißtechnik" component={Schweisstechnik} />
              <Route exact path="/Produktionsverfahren/Oberflächentechnik" component={Oberflaechentechnik} />
              <Route exact path="/Produktionsverfahren/Montage" component={Montage} />
              <Route exact path="/Dienstleistungen" component={Dienstleistungen} />
              <Route exact path="/Dienstleistungen/Konstruktion" component={Konstruktion} />
              <Route exact path="/Dienstleistungen/Logistik" component={Logistik} />
              <Route exact path="/Dienstleistungen/Qualitätssicherung" component={QS} />
              <Route exact path="/Unternehmen" component={Unternehmen} />
              <Route exact path="/Unternehmen/Ansprechpartner" component={Ansprechpartner} />
              <Route exact path="/Stellenangebote" component={Stellenangebote} />
              <Route exact path="/Unternehmen/Über uns" component={History} />
              <Route exact path="/Kontakt" component={Kontakt} />
              <Route exact path="/Unternehmen/Zertifizierungen/:current?" component={Zertifikate} />
              <Route exact path="/Datenschutz/:current?" component={Datenschutz} />
              <Route exact path="/AGBs/:current?" component={AGB} /> 
              <Route exact path="/Impressum" component={Impressum} />
              <Route component={PageNotFound} />
            </Switch>
          </animated.div>
        )}
      </Transition> */}
    </main>
  )
}

export default Main
