import CircularProgress from "@mui/material/CircularProgress"
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react"
import { Helmet } from "react-helmet"
import { SizeMe } from "react-sizeme"
import _items from "../../../../context/data/history.json"
import { COLOR_3, HEADER_HEIGHT } from "../../../../context/theme/constants"
import { useISIE } from "../../../../helper/hooks"
import { genTitle } from "../../../../helper/util"
import LightBoxContainer from "../../../../modules/LightBoxContainer"
import { Desktop, Mobile } from "../../../../modules/responsive"
import Separator from "../../../../modules/separator"
import "./style.scss"

const HistoryPage: React.FC = () => {
  const ie = useISIE()
  const items = useMemo(() => {
    return _items.map(item => ({
      ...item,
      index: item.year.toString(),
      images: item.images.map(src => ({
        src: ie ? src : src.replace(/\.\w+$/, ".webp"),
        alt: src.replace(/\.\w+$/, ""),
      })),
    }))
  }, [ie])

  return (
    <div id="HistoryPage">
      <Helmet>
        <title> {genTitle("Über uns")} </title>
        <meta
          name="description"
          content="Unsere Firmenhistorie von Gründung bis heute."
        />
      </Helmet>

      <Desktop up>
        <History items={items} />
      </Desktop>
      <Mobile>
        <HistoryMobile items={items} />
      </Mobile>
    </div>
  )
}

export default HistoryPage

type HistoryItem = {
  index: string
  title: string
  content: string
  images?: {
    src: string
    alt: string
  }[]
}
type HistoryProps = {
  items: HistoryItem[]
}

function History({ items }: HistoryProps): ReactElement<HistoryProps> {
  const [focus, setFocus] = useState(0)
  const [childNodes, setChildNodes] = useState<HTMLCollection>()

  const getChildNodes = (node: HTMLDivElement) => {
    if (node?.children) setChildNodes(node.children)
  }

  const onScroll = useCallback(() => {
    if (!childNodes) return
    const y = (window.scrollY + HEADER_HEIGHT + window.innerHeight * 0.2) | 0
    const focusElement = Array.from(childNodes).find((node: Element) => {
      if (!(node instanceof HTMLDivElement)) return false
      const nodeStart = node.offsetTop
      const nodeEnd = node.offsetTop + node.offsetHeight
      return nodeStart <= y && nodeEnd >= y
    })
    if (
      !focusElement ||
      !(focusElement instanceof HTMLDivElement) ||
      !focusElement.dataset.index
    )
      return
    const newIndex = parseInt(focusElement.dataset.index)
    setFocus(newIndex)
  }, [setFocus, childNodes])

  const onClick = useCallback(
    (index: number) => {
      if (!childNodes) return
      const child = childNodes[index]
      if (!child) return
      window.scrollTo({
        top: (childNodes[index] as HTMLDivElement).offsetTop,
      })
    },
    [childNodes]
  )

  useEffect(() => {
    const l_onScroll = () => {
      onScroll()
    }
    window.addEventListener("scroll", l_onScroll)
    return () => window.removeEventListener("scroll", l_onScroll)
  }, [onScroll])

  return (
    <div className="History">
      <div className="History-Timeline">
        {items.map(({ index, title }, i) => (
          <div
            key={index}
            onClick={() => onClick(i)}
            className={`History-Timeline-Item ${i === focus ? "focus" : ""}`}
          >
            <span> {index} </span>
            <span> {title} </span>
          </div>
        ))}
      </div>
      <SizeMe monitorHeight monitorWidth={false}>
        {({ size }) => (
          <>
            <div ref={getChildNodes} className="History-Content">
              {items.map(({ index, content, images }, i) => (
                <div
                  key={index}
                  className={`History-Content-Item ${
                    i === focus ? "focus" : ""
                  }`}
                  data-index={i}
                  onScroll={onScroll}
                >
                  {!images ? (
                    <h1 className="History-Content-Item-Image placeholder flex center">
                      IMAGE
                    </h1>
                  ) : (
                    <LightBoxContainer
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, auto)",
                        gap: "5px",
                      }}
                      images={images}
                    />
                  )}
                  <article className="History-Content-Item-Text">
                    {content}
                  </article>
                </div>
              ))}
            </div>
            <div
              style={{
                height: `${size.height || 0}px`,
                visibility: "hidden",
              }}
            />
          </>
        )}
      </SizeMe>
    </div>
  )
}

export const HISTORY_MOBILE_TIMELINE_HEIGHT = 80

function calcFocusY() {
  const y =
    HEADER_HEIGHT +
    HISTORY_MOBILE_TIMELINE_HEIGHT +
    (window.innerHeight - HEADER_HEIGHT - HISTORY_MOBILE_TIMELINE_HEIGHT) /
      Math.PI
  return y
}

function HistoryMobile({
  items,
}: HistoryProps): ReactElement<HistoryProps> | null {
  const [currentIndex, setIndex] = useState(0)
  const [itemsRefs, setitemRefs] = useState<HTMLDivElement[]>([])
  const [focusY, setFocusY] = useState(0)

  const addItemRef = useCallback(
    (ref: HTMLDivElement | null) => {
      if (!ref) return
      setitemRefs(state => {
        state.push(ref)
        return state
      })
    },
    [setitemRefs]
  )

  const onScroll = useCallback(() => {
    if (itemsRefs.length === 0) return
    const relFocusY = window.scrollY + focusY
    const index = itemsRefs.findIndex(node => {
      return (
        node.offsetTop <= relFocusY &&
        node.offsetTop + node.offsetHeight + 50 >= relFocusY
      )
    })
    if (index === -1 || index === currentIndex) return
    setIndex(index)
  }, [currentIndex, focusY, setIndex, itemsRefs])

  useLayoutEffect(() => {
    if (itemsRefs.length === 0) return
    const firstNode = itemsRefs[0]
    const yOfFirst = firstNode.offsetTop + firstNode.offsetHeight
    setFocusY(Math.min(yOfFirst, calcFocusY()))
  }, [itemsRefs])

  useEffect(() => {
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [onScroll])

  const currentValue = useMemo(() => {
    const value = (100 * currentIndex) / (items.length - 1)
    return Math.max(1, value)
  }, [currentIndex, items.length])

  useEffect(() => {
    function move(event: KeyboardEvent) {
      let index = -1
      switch (event.key) {
        case "ArrowLeft":
          index = currentIndex - 1
          break
        case "ArrowRight":
          index = currentIndex + 1
          break
        default:
          return
      }
      if (index < 0 || index >= itemsRefs.length || currentIndex === index)
        return
      const scrollY =
        itemsRefs[index].offsetTop -
        HEADER_HEIGHT -
        HISTORY_MOBILE_TIMELINE_HEIGHT -
        20
      window.scrollTo(0, scrollY)
    }
    document.addEventListener("keyup", move)
    return () => document.removeEventListener("keyup", move)
  }, [currentIndex, setIndex, items.length, itemsRefs])

  if (items.length === 0) return null
  return (
    <div className="HistoryMobile">
      <div
        className="HistoryMobile-Timeline color-1 flex column center fullwidth"
        style={{ height: `${HISTORY_MOBILE_TIMELINE_HEIGHT}px` }}
      >
        <div className="HistoryMobile-Timeline-Index fullwidth">
          <CircularProgress
            style={{
              color: COLOR_3.background,
              gridColumn: "1 / 2",
              gridRow: "1/2",
              transform: `translateX(8px) rotate(-90deg)`,
            }}
            variant="determinate"
            size="60"
            value={currentValue}
          />
          <h4 style={{ gridColumn: "1 / 2", gridRow: "1/2" }}>
            {" "}
            {items[currentIndex].index}{" "}
          </h4>
          <h4> {items[currentIndex].title} </h4>
        </div>
      </div>
      <div className="HistoryMobile-Content">
        {items.map(({ index, title, images = [], content }, i) => (
          <React.Fragment key={index}>
            <div className="HistoryMobile-Content-Item" ref={addItemRef}>
              <LightBoxContainer
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, auto)",
                  gap: "5px",
                }}
                images={images}
              />
              <article className="HistoryMobile-Content-Item-Text pad-vw-5 Paper">
                {content}
              </article>
            </div>
            {i !== items.length - 1 && (
              <Separator mode="horizontal" style={{ margin: "1em auto" }} />
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="HistoryMobile-Marker" style={{ top: focusY }}></div>
    </div>
  )
}
