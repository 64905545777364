import React from 'react'
import Download, { DownloadTypes } from '../../../../modules/download'
import { COLOR_4 } from '../../../../context/theme/constants'
import { Helmet } from "react-helmet"
import { genTitle } from '../../../../helper/util'

const Konstruktion = () => {
  return (
    <div id="Konstruktion">
      <Helmet>
        <title> {genTitle("Konstruktion")} </title>
      </Helmet>

      <img src="/image/Büro%20-%20Konstruktionsbüro.jpg" alt="konstruktion" />
      <p>Von der Idee bis zum fertigen Produkt: Unsere Konstrukteure unterstützen Sie bei jedem Schritt und überwachen die Produktion ihres Bauteils über den gesamten Fertigungsprozess. So können wir auch engste Toleranzen und Sauberkeit einhalten, wie sie beispielweise in der Medizin- und Lebensmittelbranche gefordert werden.</p>
      <p>Höchste Sicherheit Ihrer Daten und absolute Diskretion sind dabei für uns selbstverständlich. Ebenso können Sie sich darauf verlassen, von uns eine kostengünstige Lösung zu erhalten, die in jeder Hinsicht überzeugt.</p>
      <p>Unser kompetentes Team setzt dafür die folgende 3D-CAD/CAM Systeme ein:</p>
      <ul className="flex space-around wrap undecorated pad-items-20px Paper " >
        <li>Inventor</li>
        <li>Creo Elements</li>
        <li>Tube Design</li>
      </ul>
      <div>
        <p>
          Um eine schnellstmögliche Bearbeitung ihrer Anfragen und Aufträge zu gewährleisten, würden wir Sie bitten bei Zeichnungsdaten die folgenden Formate zu benutzen:
        </p>
        <div className="flex Paper center" style={{ border: `1px solid ${COLOR_4.background}` }} >
          <table className="font-big font-shadowed" style={{ width: "50%", minWidth: "300px", borderSpacing: "1em",  }} >
          <tbody>
            <tr>
              <td>3D-Formate</td>
              <td>.step / .stp / .iges / .sat</td>
            </tr>
            <tr>
              <td>2D-Formate</td>
              <td>.dxf / .dwg / .geo</td>
            </tr>
          </tbody>
          </table>
        </div>
        <div className="flex space-around wrap align-center fullwidth" >
          <p>
            Eine Übersicht über sämtliche Dateiformate, die wir verarbeiten können finden Sie unter folgendem Link:
          </p>
          <Download src="/pdf/Dateiformate.pdf" type={DownloadTypes.pdf}>Download</Download>
        </div>
      </div>
    </div>
  )
}

export default Konstruktion
