import React from "react"
import NavPage from "../../templates/nav-page"
import menu_conf from "../../../../context/data/menu.json"
import { Helmet } from "react-helmet"
import { genTitle } from "../../../../helper/util"

const top = menu_conf.top
const pf = top.find(tp => tp.title === "Unternehmen")
const pages = pf ? pf.children : []

const Unternehmen = () => {
  return (
    <div id="Unternehmen">
      <Helmet>
        <title> {genTitle("Unternehmen")} </title>
        <meta
          name="description"
          content="Ansprechpartner, Unternehmenshistorie, Zertifizierungen"
        />
      </Helmet>

      <NavPage pages={pages} />
    </div>
  )
}

export default Unternehmen
